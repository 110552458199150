








































































import BaseSkeletonLine from "../../Base/BaseSkeletonLine.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseLink,
        BaseCard,
        BaseSkeletonLine,
    },
})
export default class SharedCompaniesProfileData extends Vue {
    @Prop() accounts!: any[];
    @Prop() user!: any;
    @Prop() loading!: boolean;

    toDisplayName(user: any) {
        return toDisplayName(user);
    }

    snsIcon(sns: any) {
        let icon = "mdi-whatsapp";

        if (sns.type === "Facebook") icon = "mdi-facebook";
        if (sns.type === "Instagram") icon = "mdi-instagram";
        if (sns.type === "Twitter") icon = "mdi-twitter";
        if (sns.type === "Website") icon = "mdi-web";

        return icon;
    }

    snsValue(sns: any) {
        let value = sns.username;

        if (sns.type === "Facebook") value = "/" + sns.username;
        if (sns.type === "Instagram") value = "@" + sns.username;
        if (sns.type === "Twitter") value = "@" + sns.username;
        if (sns.type === "Website") value = sns.username;

        return value;
    }

    snsLink(sns: any) {
        let link = sns.username;

        if (sns.type === "Facebook") link = "fb.me/" + sns.username;
        if (sns.type === "Instagram") link = "instagram.com/" + sns.username;
        if (sns.type === "Twitter") link = "twitter.com/" + sns.username;
        if (sns.type === "Website") link = sns.username;

        return link;
    }

    get snsAccounts() {
        let snsAccounts: any[] = [];
        const isUserExist: boolean = !!this.user;
        const userHasProfile: boolean = this.user && this.user.profile;

        if (!isUserExist || !userHasProfile) return snsAccounts;

        snsAccounts = [
            {
                type: "WhatsApp",
                username: this.user.profile.phone_number || null,
            },
            {
                type: "Instagram",
                username: this.user.profile.instagram_account || null,
            },
            {
                type: "Twitter",
                username: this.user.profile.twitter_account || null,
            },
            { type: "Website", username: this.user.profile.website || null },
        ];

        return snsAccounts;
    }
}
