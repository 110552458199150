






























































































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toCurrency, toDate } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
    },
})
export default class SharedCompaniesActivityItem extends Vue {
    @Prop() proposal!: any;
    @Prop() fund!: any;
    @Prop() hideCompanyName!: boolean;

    get me() {
        return this.$store.state.users.me;
    }

    updateClickable() {}

    toCurrency(value: any) {
        return toCurrency(value);
    }

    toDate(date: any) {
        return toDate(date);
    }
}
