



































































import BaseSkeletonLine from "../../components/Base/BaseSkeletonLine.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedCompaniesActivity from "@/components/Shared/Companies/Activity.vue";
import SharedCompaniesProfileAvatar from "@/components/Shared/Companies/ProfileAvatar.vue";
import SharedCompaniesProfileData from "@/components/Shared/Companies/ProfileData.vue";
import SharedCompaniesProfilePhotos from "@/components/Shared/Companies/ProfilePhotos.vue";
import SharedCompaniesProfileStatistic from "@/components/Shared/Companies/ProfileStatistic.vue";
import { toCurrency, toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
        BaseAvatar,
        BaseLink,
        SharedCompaniesProfileAvatar,
        SharedCompaniesProfileData,
        SharedCompaniesActivity,
        SharedCompaniesProfileStatistic,
        SharedCompaniesProfilePhotos,
        BaseSkeletonLine,
    },
    metaInfo() {
        const company = this.$store.state.users.user;
        let companyDisplayName = "";
        if (company) companyDisplayName = toDisplayName(company);

        const page = "Profil - ";
        const title = page + companyDisplayName;

        return {
            title: title,
        };
    },
})
export default class AppCompanyId extends Vue {
    @Prop() companyId!: string;

    public isLoading: boolean = false;

    public images: any[] = [];

    toCurrency(value: any) {
        return toCurrency(value);
    }

    get user() {
        return this.$store.state.users.user;
    }

    get statistics() {
        const statistics: any[] = [
            {
                text: "proposal diberikan dana",
                value: this.funds.length,
                icon: "mdi-clipboard-check",
            },
            {
                text: "total dana diberikan",
                value: this.toCurrency(this.totalGivenFund),
                icon: "mdi-bank-transfer-out",
            },
        ];

        return statistics;
    }

    get totalGivenFund() {
        let total: number = 0;

        this.funds.map((fund: any) => {
            total += fund.nominal;
        });

        return total;
    }

    get funds() {
        return this.$store.state.funds.funds;
    }

    get plan() {
        const plans = this.$store.state.plans.dashboardPlans;
        let plan: any = {};

        if (plans.length) plan = plans[0];

        return plan;
    }

    galleryImage(fund: any) {
        let galleryImage: any = null;
        const userId: string = this.companyId;

        const findUserGallery = fund.proposal.galleries.filter(
            (gallery: any) => {
                return gallery.user.id === userId;
            }
        );

        if (findUserGallery && findUserGallery.length)
            galleryImage = findUserGallery[0];

        return galleryImage;
    }

    setImages(galleries: any) {
        const images: any[] = [];
        const items: string[] = [
            "image_0_path",
            "image_1_path",
            "image_2_path",
        ];

        galleries.gallery.funds.map((fund: any) => {
            const image = this.galleryImage(fund);

            if (!image) return;

            const isImage0Exist: boolean = !!image.image_0_path;
            const isImage1Exist: boolean = !!image.image_1_path;
            const isImage2Exist: boolean = !!image.image_2_path;
            const hasProfile: boolean =
                image && image.user && image.user.profile;

            if (isImage0Exist)
                images.push({
                    companyName: hasProfile
                        ? image.user.profile.display_name
                        : "",
                    path: image.image_0_path,
                });
            if (isImage1Exist)
                images.push({
                    companyName: hasProfile
                        ? image.user.profile.display_name
                        : "",
                    path: image.image_1_path,
                });
            if (isImage2Exist)
                images.push({
                    companyName: hasProfile
                        ? image.user.profile.display_name
                        : "",
                    path: image.image_2_path,
                });

            // images.push(image);
        });

        this.images = images;
    }

    async getGalleryByUserId() {
        const gallery = await this.$store.dispatch(
            "galleries/getGalleryByUserId",
            this.companyId
        );

        return gallery;
    }

    async getFundByUserId() {
        const payload: any = {
            user: this.companyId,
            year: new Date().getFullYear(),
        };
        const funds = await this.$store.dispatch(
            "funds/getAllFundByUser",
            payload
        );

        return funds;
    }

    async getCompanyPlan() {
        const payload = {
            userId: this.companyId,
            year: new Date().getFullYear(),
        };

        const plans: any = await this.$store.dispatch(
            "plans/getDashboardPlan",
            payload
        );

        return plans;
    }

    async mounted() {
        this.isLoading = true;

        const user = await this.$store.dispatch(
            "users/getOneUser",
            this.companyId
        );

        const plans = await this.getCompanyPlan();

        const galleries = await this.getGalleryByUserId();
        this.setImages(galleries);

        const funds = await this.getFundByUserId();

        setTimeout(() => {
            this.isLoading = false;
        }, 200);
    }
}
