














import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseAvatar,
    },
})
export default class SharedCompaniesProfileAvatar extends Vue {
    @Prop() user!: any;
    @Prop() loading!: boolean;
}
