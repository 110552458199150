












import { Component, Prop, Vue } from "vue-property-decorator";
import companyId from "../App/_companyId.vue";

@Component({
    components: {
        companyId,
    },
})
export default class WebCompany extends Vue {
    @Prop() companyId!: string;
}
