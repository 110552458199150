var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-card',{attrs:{"disabled":!_vm.me,"customClass":"pa-3 mb-4"}},[_c('v-layout',[(!_vm.hideCompanyName)?_c('base-avatar',{attrs:{"size":32,"user":_vm.proposal.user}}):_vm._e(),_c('v-layout',{staticClass:"ml-3",attrs:{"column":""}},[(!_vm.hideCompanyName)?_c('p',{staticClass:"mb-0 mt-n1 caption text--disabled",domProps:{"textContent":_vm._s(new Date().toLocaleString())}}):_vm._e(),_c('base-link',{attrs:{"to":'/app/proposal/' + _vm.proposal.id},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('p',{staticClass:"mb-1 title",class:hover ? 'primary--text cursor-pointer' : '',domProps:{"textContent":_vm._s(_vm.proposal.title)}})]}}])})],1),_c('v-layout',{staticClass:"mb-2 align-center",attrs:{"wrap":""}},[_c('span',{staticClass:"caption text--secondary",domProps:{"textContent":_vm._s('Pengusul')}}),_c('base-link',{attrs:{"to":'/app/pengusul/' + _vm.proposal.user.id},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-layout',{staticClass:"align-center"},[_c('base-avatar',{staticClass:"ml-2 mr-1",attrs:{"size":24,"user":_vm.proposal.user}}),_c('span',{staticClass:"caption mr-2",class:hover
                                            ? 'text-decoration-underline cursor-pointer'
                                            : '',domProps:{"textContent":_vm._s(
                                        _vm.proposal.user.profile.display_name
                                    )}})],1)]}}])})],1),_c('base-link',{attrs:{"to":'/app/proposal?kategori=' +
                            _vm.proposal.category.name},on:{"click":_vm.updateClickable}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var hover = ref.hover;
return [_c('p',{staticClass:"mb-0 caption",class:hover
                                        ? 'text--primary cursor-pointer'
                                        : 'text--secondary',domProps:{"textContent":_vm._s('#' + _vm.proposal.category.name)}})]}}])})],1)],1),_c('v-layout',{staticClass:"align-center",attrs:{"wrap":""}},[_c('span',{staticClass:"mr-2 body-2 font-weight-light",domProps:{"textContent":_vm._s('Dana yang diberikan')}}),_c('span',{staticClass:"body-2 font-weight-bold",domProps:{"textContent":_vm._s(_vm.toCurrency(_vm.fund.nominal))}})]),_c('v-layout',{staticClass:"align-center",attrs:{"wrap":""}},[_c('span',{staticClass:"mt-2 caption text--secondary",domProps:{"textContent":_vm._s(_vm.toDate(_vm.fund.created_at))}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }