





































import BaseCard from "@/components/Base/BaseCard.vue";
import SharedProposalListItem from "@/components/Shared/Proposal/ListItem.vue";
import { sortArray } from "@/helpers/arrayHelper";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedCompaniesActivityItem from "./ActivityItem.vue";

@Component({
    components: {
        SharedProposalListItem,
        BaseCard,
        SharedCompaniesActivityItem,
    },
})
export default class SharedCompaniesActivity extends Vue {
    @Prop() funds!: any[];
    @Prop() hideCompanyName!: boolean;
    @Prop() loading!: boolean;

    get sortedFunds() {
        let sortedFunds = sortArray(this.funds, "created_at", true);

        return sortedFunds;
    }
}
